<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="names" class="label_input">Nombres*</label>
          <b-form-input
            id="names"
            size="sm"
            placeholder="Nombres"
            v-model="formData.names"
            :disabled="isDisabled"
            autofocus
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="last_names" class="label_input">Apellidos*</label>
          <b-form-input
            id="last_names"
            size="sm"
            placeholder="Apellidos"
            v-model="formData.last_names"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3" v-if="record">
        <div role="group">
          <label for="username" class="label_input">Usuario</label>
          <b-form-input
            id="username"
            size="sm"
            placeholder="Usuario"
            v-model="formData.username"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de identificación*</label>
          <v-select
            :options="typeIndetificatios"
            label="value"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.type_identification"
            :disabled="isDisabled"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="identification" class="label_input">Identificación*</label>
          <b-form-input
            id="identification"
            size="sm"
            placeholder="Identificación"
            v-model="$v.formData.identification.$model"
            @blur="$v.formData.identification.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.identification.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.identification) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="email" class="label_input">Correo electrónico*</label>
          <b-form-input
            id="email"
            type="email"
            size="sm"
            placeholder="Correo eléctronico"
            v-model="$v.formData.email.$model"
            @blur="$v.formData.email.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.email.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.email) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone" class="label_input">Celular*</label>
          <b-form-input
            id="cellphone"
            size="sm"
            placeholder="Celular"
            v-model="$v.formData.cellphone.$model"
            @blur="$v.formData.cellphone.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.cellphone.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.cellphone) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="address" class="label_input">Dirección</label>
          <b-form-input
            id="address"
            size="sm"
            placeholder="Dirección"
            v-model="formData.address"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Género*</label>
          <v-select
            :options="typeGenders"
            label="value"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.gender"
            :disabled="isDisabled"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de perfil*</label>
          <v-select
            :options="profiles"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.profileUuid"
            :reduce="(option) => option.uuid"
            :disabled="isDisabled"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3" v-if="isSeller">
        <div role="group">
          <label for="number_sales" class="label_input">Número de ventas permitidas</label>
          <b-form-input
            id="number_sales"
            size="sm"
            placeholder="Número de ventas"
            v-model="$v.formData.number_sales.$model"
            @blur="$v.formData.number_sales.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.number_sales.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.number_sales) }}
          </span>
        </div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3" v-if="isSeller">
          <label for="number_sales" class="label_input"><strong>Número de ventas permitidas:</strong> Puedes limitar la cantidad de ventas que puede realizar el vendedor. Esto es opcional.</label>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer" v-if="!isDisabled">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUD";

export default {
  inject: ["profileRepository", "employeeRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  mixins: [ActionCRUD],
  props: {
    record: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profiles: [],
      typeStatus: [{uuid: true, label: 'Activo'}, {uuid: false, label: 'Inactivo'}],
      isBusy: false,
      formData: {
        names: "",
        last_names: "",
        gender: "",
        address: "",
        type_identification: "",
        identification: "",
        cellphone: "",
        email: "",
        profileUuid: "",
        number_sales: "",
      },
      typeIndetificatios: ["Cédula de ciudadanía", "Pasaporte"],
      typeGenders: ["Femenino", "Masculino", "Otro"],
      repository: "employeeRepository",
    };
  },
  validations() {
    const defaultValidation = {
      names: {
        required,
      },
      last_names: {
        required,
      },
      gender: {
        required,
      },
      type_identification: {
        required,
      },
      identification: {
        required,
        numeric,
      },
      cellphone: {
        required,
        numeric,
        minLength: minLength(10),
				maxLength: maxLength(10),
      },
      email: {
        required,
        email,
      },
      profileUuid: {
        required,
      }
    }

    return {
      formData: {
        ...defaultValidation,
        number_sales: {
          numeric,
        }
      }
    }
  },
  computed: {
    isSeller() {
      const me = this;
      let seller = false;

      seller = me.formData.profileUuid === "mam783bb-275c-21m4-912b-24c3d0m547e2" ? true : false;
      return seller;
    },
  },
  async mounted() {
    const me = this;
    await me.loadProfiles();
    if (me.record) {
      me.formData = { ...me.formData, ...me.record };
      delete me.formData.user;
      delete me.formData.phone;
      delete me.formData.status;
      delete me.formData.photo;
    }
  },
  methods: {
    async loadProfiles() {
      const me = this;
      try {
        const res = await me.profileRepository.getAll();
        const { data } = res;
        data.forEach((el) => {
          if (
            el.uuid != "cda783bb-338c-41b4-912b-10c3d2c247e3" &&
            el.uuid != "bcaa1cc2-72f3-4183-a36b-03e4c7d845ba" &&
            el.uuid != "212801d0-1dd7-4b9e-9f7f-50591faedbff"
          ) {
            me.profiles.push(el);
          }
        });
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
  },
};
</script>
