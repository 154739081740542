<template>
  <div>
    <b-container fluid>
      <button-back />
      <b-row>
        <b-col cols="12">
          <h3>Empleados</h3>
          <ButtonTutotial :module="module" />
        </b-col>
      </b-row>
      <b-card no-body class="card-global overflow-hidden">
        <b-row class="mb-3">
          <b-col lg="6" md="6" sm="12">
            <b-row>
              <b-col lg="6" md="6" sm="12">
                <div role="group">
                  <label class="label_input_black">N° de usuarios segun tú plan</label>
                  <b-form-input size="sm" v-model="number_users" disabled />
                </div>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <div role="group">
                  <label class="label_input_black">N° de usuarios registrados</label>
                  <b-form-input size="sm" v-model="records.length" disabled />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <base-action-table
          :records="records"
          :fields="fields"
          :busy="isBusy"
          :filtering="filtering"
          :disabled="disabled"
          :module="module"
          @creating="handleCreating"
          @detail="handleDetail"
          @editing="handleEditing"
          @deleting="handleDeleting"
        >
        </base-action-table>
      </b-card>
    </b-container>

    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-employee :record="record" :isDisabled="isDisabled" @save="save" />
      </template>
    </base-modal>
  </div>
</template>

<script>
import { BContainer, BCol, BRow, BFormInput, BCard } from "bootstrap-vue";
import ActionCRUD from "@/mixins/ActionCRUD";
import { mapGetters } from "vuex";
import BaseActionTable from "@/components/base/BaseActionTable";
import BaseModal from "@/components/base/BaseModal";
import FormEmployee from "./FormEmployee";
import ButtonBack from "@/components/buttons/ButtonBack";
import ButtonTutotial from "@/components/buttons/ButtonTutotial";
export default {
  inject: ["employeeRepository"],
  mixins: [ActionCRUD],
  components: {
    BContainer,
    BCol,
    BRow,
    BFormInput,
    BCard,
    BaseActionTable,
    BaseModal,
    FormEmployee,
    ButtonBack,
    ButtonTutotial,
  },
  data() {
    return {
      records: [],
      record: null,
      fields: [
        {
          key: "names",
          label: "Nombres",
          sortable: true,
        },
        {
          key: "last_names",
          label: "Apellidos",
          sortable: true,
        },
        {
          key: "identification",
          label: "Identificación",
          sortable: true,
        },
        {
          key: "user.username",
          label: "Usuario",
          sortable: true,
        },
        {
          key: "cellphone",
          label: "Celular",
          sortable: true,
        },
        {
          key: "user.profile.name",
          label: "Perfil",
          sortable: true,
        },
        {
          key: "email",
          label: "Correo",
          sortable: true,
        },
        { key: "actions", label: "Accion" },
      ],
      isBusy: false,
      filtering: true,
      isDisabled: false,
      disabled: false,
      title: "Crear empleado",
      id: "employee-modal",
      module: "64f83b65-281b-4a14-beaa-2a13203de5f2",
      repository: "employeeRepository",
      number_users: "0",
    };
  },
  watch: {
    records() {
      this.validationUserCreate();
    }
  },
  computed: {
    ...mapGetters("user", ["getEnterprise"]),
  },
  async mounted() {
    const me = this;

    await me.handleLoadData();
    me.validationUserCreate();
  },
  methods: {
    handleCreating() {
      const me = this;
      me.title = "Crear Empleado";
      me.isDisabled = false;

      me.record = null;
      me.$bvModal.show(me.id);
    },
    handleDetail(item) {
      const me = this;
      me.title = "Detalle Empleado";
      me.isDisabled = true;
      me.record = {
        ...item,
        profileUuid: item.user.profile.uuid,
        username: item.user.username,
      };
      me.$bvModal.show(me.id);
    },
    handleEditing(item) {
      const me = this;
      me.title = "Actualizar Empleado";
      me.isDisabled = false;
      me.record = {
        ...item,
        profileUuid: item.user.profile.uuid,
        username: item.user.username,
      };
      me.$bvModal.show(me.id);
    },
    async handleDeleting(id) {
      await this.del(id);
    },
    validationUserCreate() {
      const me = this;
      const { number_users } = me.getEnterprise;

      me.number_users = number_users === "1000" ? "Ilimitados" : number_users;
      
      me.disabled = Number(number_users) == me.records.length ? true : false;
    },
  },
};
</script>
